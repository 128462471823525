<template>
    <n-list value="shops" text="分店" :fields="fields" :items="shops" />
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            fields: [
                {
                    key: 'name',
                    label: '名稱',
                    sortable: true,
                },
                {
                    key: 'address',
                    label: '地址',
                    sortable: true,
                    class: 'd-none d-sm-table-cell',
                },
                {
                    key: 'phone',
                    label: '電話',
                    sortable: true,
                    
                },
            ]
        }
    },

    created() {
        this.$store.dispatch('bindCollectionByMerchant', { col: 'shops', merchantId: localStorage.merchantId })
    },

    computed: {
        ...mapState({
            shops: state => state.shops,
        })
    }
}
</script>